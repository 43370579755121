import React from 'react';
import clsx from 'clsx';
import { BadgePositionEnum } from '@/components/Badge/types';
import classes from './styles/badge.module.scss';

interface IBadge {
    position?: BadgePositionEnum;
    text: string;
}

const Badge = ({ position, text }: IBadge) => {
    const className = clsx(classes.root, position === BadgePositionEnum.BOTTOM_LEFT && classes.root);

    return <div className={className}>{text}</div>;
};

export default Badge;
