'use client';

import React, { ReactElement, useContext, useMemo } from 'react';
import { useAppContext } from '@/lib/context';
import { useKlarnaMessageContext } from '../context/KlarnaMessageContext';
import { ProductContext } from '@/components/RootComponents/Product/context/productContext';

interface IPayPalMessage {
    amount?: number;
}

const KlarnaMessage = ({ amount }: IPayPalMessage): ReactElement | null => {
    const { productPrice } = useContext(ProductContext);

    const amountForKlarna = productPrice?.minPrice || amount;

    const [
        {
            storeConfig: { storeName, websiteCode },
        },
    ] = useAppContext();

    const { isScriptLoaded } = useKlarnaMessageContext();

    const paymentCard = useMemo(() => {
        if (!amountForKlarna || !isScriptLoaded || !storeName || !websiteCode) return null;

        return (
            // @ts-expect-error special Klarna syntax
            <klarna-placement
                data-key="credit-promotion-badge"
                data-locale={`${storeName.toLowerCase()}-${websiteCode.toUpperCase()}`}
                data-purchase-amount={amountForKlarna * 100}
                // @ts-expect-error special Klarna syntax
            ></klarna-placement>
        );
    }, [amountForKlarna, isScriptLoaded, websiteCode, storeName]);

    return <>{paymentCard}</>;
};

KlarnaMessage.displayName = 'KlarnaMessage';

export default KlarnaMessage;
